// Import the needed libraries
import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthContext } from './context/AuthContext'
import { useLocation } from 'react-router-dom'

function useCommonSetup(site = '') {
  // Setup the languages
  const { t, i18n } = useTranslation('common')

  // Get the auth processes
  const { auth, setAuth, login, logout } = useContext(AuthContext)

  // Setup the location variables
  const location = useLocation()
  const from = location.state?.from || '/'
  const locationState = location.state
  const currentLocation = location.pathname

  // Setting up the page title
  useEffect(() => {
    if (site !== '') document.title = `${t(`${site}.title`)} - Alexa's Photo Carousel`
  }, [t, site])

  //const serverUrl = 'http://localhost:5000/api'
  const serverUrl = 'https://photo-carousel.com/api'

  return { t, i18n, auth, setAuth, login, logout, from, locationState, currentLocation, serverUrl }
}

export default useCommonSetup
