import React, { createContext, useState, useEffect } from 'react'
import { PropTypes } from 'prop-types'
import axiosInstance from '../axiosInstance.ts'

const AuthContext = createContext()

const AuthProvider = ({ children }) => {
  console.log('AuthProvider')
  //const serverUrl = 'http://localhost:5000/api'
  const serverUrl = 'https://photo-carousel.com/api'
  const [auth, setAuth] = useState(null)

  useEffect(() => {
    // check if the user is logged in
    const checkAuth = async () => {
      try {
        const response = await axiosInstance.get(`${serverUrl}/auth/check-auth`, {
          withCredentials: true,
        })
        setAuth(response.data.user)
      } catch (error) {
        setAuth(null)
      }
    }
    checkAuth()
  }, [serverUrl])

  const login = async (email, password) => {
    try {
      const response = await axiosInstance.post(
        `${serverUrl}/auth/login`,
        { email, password },
        { withCredentials: true },
      )
      setAuth(response.data.user)
      return { status: 200 }
    } catch (error) {
      if (error.response) {
        return {
          status: error.response.status,
          message: error.response.data,
        }
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        //console.log('Login failed with status code:', error.response.status)
        //console.log('Response data:', error.response.data)
      } else if (error.request) {
        return { status: 500 }
        // The request was made but no response was received
        //console.log('No response received:', error.request)
      } else {
        return { status: 500 }
        // Something happened in setting up the request that triggered an Error
        //console.log('Error:', error.message)
      }
    }
  }

  const logout = async () => {
    try {
      await axiosInstance.post(`${serverUrl}/auth/logout`, {}, { withCredentials: true })
      setAuth(null)
    } catch (error) {
      console.log('Logout failed', error)
    }
  }

  return (
    <AuthContext.Provider value={{ auth, setAuth, login, logout }}>{children}</AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export { AuthContext, AuthProvider }
