import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import { Provider } from 'react-redux'
import store from './store'

import { BrowserRouter } from 'react-router-dom'

import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'

import { AuthProvider } from 'src/components/context/AuthContext'

import common_en from 'src/translations/en/translation.json'
import common_nl from 'src/translations/nl/translation.json'

i18next.init({
  interpolation: { escapeValue: false },
  lng:
    localStorage.getItem('lng') ||
    (() => {
      const browserLang = navigator.language.slice(0, 2).toLowerCase() // Get the first two characters
      return ['en', 'nl'].includes(browserLang) ? browserLang : 'en' // Check if it's 'en' or 'nl', otherwise fallback to 'en'
    })() ||
    'en',
  resources: {
    en: {
      common: common_en,
    },
    nl: {
      common: common_nl,
    },
  },
  fallbackLng: 'en',
})

createRoot(document.getElementById('root')).render(
  <BrowserRouter>
    <I18nextProvider i18n={i18next}>
      <Provider store={store}>
        <AuthProvider>
          <App />
        </AuthProvider>
      </Provider>
    </I18nextProvider>
  </BrowserRouter>,
)
