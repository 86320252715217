import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

// Define the response type for the refresh token endpoint
interface RefreshTokenResponse {
  newAccessToken: string
}

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: 'https://photo-carousel.com/api', // Your API base URL
  //baseURL: 'http://localhost:5000/api', // Your API base URL
  withCredentials: true, // This will send cookies with the request
})

// Flag to prevent multiple refresh requests
let isRefreshing = false
let refreshSubscribers: Array<(token: string) => void> = []

// Function to handle request retries after token is refreshed
function onRefreshed(token: string) {
  refreshSubscribers.forEach((callback) => callback(token))
  refreshSubscribers = []
}

// Add a response interceptor to handle 401 errors
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response, // Pass through successful responses
  async (error) => {
    const originalRequest: AxiosRequestConfig & { _retry?: boolean } = error.config

    // If the error is a 401 Unauthorized and it's not a retry
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      // Mark the request as a retry attempt
      originalRequest._retry = true

      // If another request is already refreshing the token, queue this one
      if (isRefreshing) {
        return new Promise((resolve) => {
          refreshSubscribers.push((token: string) => {
            // Update the Authorization header with the new token
            if (originalRequest.headers) {
              originalRequest.headers['Authorization'] = `Bearer ${token}`
            }
            resolve(axiosInstance(originalRequest))
          })
        })
      }

      // Mark that a token refresh request is in progress
      isRefreshing = true

      try {
        // Call the refresh token endpoint
        const { data }: AxiosResponse<RefreshTokenResponse> = await axios.post(
          'https://photo-carousel.com/api/auth/refresh-token',
          //'http://localhost:5000/api/auth/refresh-token',
          {},
          { withCredentials: true }, // Ensure that cookies are sent in the request
        )

        const newAccessToken = data.newAccessToken

        // Set the Authorization header for future requests
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`

        // Execute any queued requests waiting for the token refresh
        onRefreshed(newAccessToken)

        // Retry the original request with the new token
        if (originalRequest.headers) {
          originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`
        }

        return axiosInstance(originalRequest)
      } catch (refreshError) {
        console.error('Refresh token failed', refreshError)
        return Promise.reject(refreshError)
      } finally {
        // Reset the refreshing flag
        isRefreshing = false
      }
    }

    return Promise.reject(error) // For all other errors, just reject the promise
  },
)

export default axiosInstance
