import React from 'react'
import { CSpinner } from '@coreui/react-pro'
import useCommonSetup from './useCommonSetup'

const Loading = () => {
  const { t } = useCommonSetup()
  return (
    <div className="pt-3 text-center mx-3">
      <CSpinner color="primary" variant="grow" />
      <p>{t('general.loading')}</p>
    </div>
  )
}

export default Loading
