// Github access token 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
import React, { Suspense, useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useColorModes } from '@coreui/react-pro'

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

//import './scss/style.scss'
//import './css/mainPage.css'
import './scss/custom style/main.scss'

// Import the needed components
import lazyWithRetry from 'src/components/lazyWithRetry'
import Loading from './components/Loading'
import { AuthProvider } from './components/context/AuthContext'
import ThemeContext from './components/context/ThemeContext'

dayjs.extend(customParseFormat)

// Containers
const DefaultLayout = lazyWithRetry(() => import('./layout/DefaultLayout'))
const MainLayout = lazyWithRetry(() => import('./layout/MainLayout'))

// Pages
const Page404 = lazyWithRetry(() => import('./views/pages/page404/Page404'))
const Page500 = lazyWithRetry(() => import('./views/pages/page500/Page500'))
const Home = lazyWithRetry(() => import('./views/mainPage/home/Home'))

const App = () => {
  const { isColorModeSet, setColorMode } = useColorModes(
    'coreui-pro-react-admin-template-theme-light',
  )
  const storedTheme = useSelector((state) => state.theme)

  /* Own code for the theme */
  const getInitialTheme = () => {
    console.log('Get the theme')
    const savedTheme = localStorage.getItem('theme')
    console.log('Found saved theme ' + savedTheme)
    if (savedTheme) {
      return savedTheme
    }
    const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
    console.log('System theme' + systemTheme)
    return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
  }
  const [theme, setTheme] = useState(getInitialTheme)
  useEffect(() => {
    // Update the `data-theme` attribute on the root element
    document.documentElement.setAttribute('data-theme', theme)
    // Save the theme to localStorage
    localStorage.setItem('theme', theme)
  }, [theme])

  /* CoreUI theme functions */
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.href.split('?')[1])
    const theme = urlParams.get('theme') && urlParams.get('theme').match(/^[A-Za-z0-9\s]+/)[0]
    if (theme) {
      setColorMode(theme)
    }

    if (isColorModeSet()) {
      return
    }

    setColorMode(storedTheme)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const suspense = (Component) => (
    <Suspense fallback={<Loading />}>
      <Component />
    </Suspense>
  )

  return (
    <AuthProvider>
      <ThemeContext.Provider value={[theme, setTheme]}>
        <Routes>
          <Route path="/404" element={suspense(Page404)} />
          <Route path="/500" element={suspense(Page500)} />
          <Route path="/dashboard/*" element={suspense(DefaultLayout)} />
          <Route path="/" element={suspense(Home)} />
          <Route path="/*" element={suspense(MainLayout)} />
        </Routes>
      </ThemeContext.Provider>
    </AuthProvider>
  )
}

export default App
